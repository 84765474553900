import React from 'react'
import Navbar from './shared/Navbar'
import Hero from './Hero'
import Footer from './shared/Footer'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ErrorPage from './ErrorPage'
import About from './About'
import Languages from './Languages'
import Portfolio from './portfolio/Portfolio'
import Resume from './Resume'
import SectionLines from './shared/SectionLines'
import HeroSm from './HeroSm'
import 'aos/dist/aos.css';

const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<>
				{/* <Navbar /> */}
				<Hero />
				<HeroSm />
				<div className='container mx-auto mb-20'>
					<About />
					<SectionLines side={'right'} />
					<Languages />
					<SectionLines side={'left'} />
					<Resume />
					<SectionLines side={'right'} />
					<Portfolio />
				</div>
				<Footer />
			</>
		),
		errorElement: <ErrorPage />,
	},
])

export class App extends React.Component {
	render() {
		return <RouterProvider router={router} />
	}
}

export default App
